<template>
  <div>
    <!-- Untuk mencegah tekan enter langsung submit -->
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Jenis Simpanan </label>
              <Dropdown
                v-model="form.jsim_id"
                :options="listJsim"
                :filter="true"
                optionValue="id"
                optionLabel="nama"
                placeholder="Pilih Jenis Simpanan"
                class="w-full"
                input-class="w-full"
                :showClear="true"
                :class="{ 'p-invalid': v$.form.jsim_id.$invalid && submitted }"
                @change="onChangeSimpanan"
              >
                <small
                  v-if="
                    (v$.form.jsim_id.$invalid && submitted) ||
                    v$.form.jsim_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.jsim_id.required.$message }}</small
                >
              </Dropdown>
            </div>
            <div class="field col-12 md:col-12">
              <div
                class="p-message p-message-info field col-12"
                role="alert" style="small-message"
              >
                <div
                  class="p-message-text p-message-text"
                  data-pc-section="text"
                >
                  Message Content
                </div>
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <label>Jumlah</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="item"
                  v-model="form.jumlah"
                  class="w-full"
                  input-class="w-full"
                  placeholder="0"
                  locale="id-ID"
                  :min-fraction-digits="0"
                  :max-fraction-digits="0"
                  :allow-empty="false"
                  :class="{ 'p-invalid': v$.form.jumlah.$invalid && submitted }"
                  @focus="selectAllText('item')"
                />
                <small
                  v-if="
                    (v$.form.jumlah.$invalid && submitted) ||
                    v$.form.jumlah.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.jumlah.required.$message }}</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          :label="editMode ? 'Ubah' : 'Tambahkan'"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import { helpers, required } from '@vuelidate/validators'
import SimpananService from '@/services/SimpananService'
import Hotkey from '@/components/Hotkey'

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    Hotkey,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    anggotaId: {
      type: Number,
      default() {
        return 0
      },
    },
    listJsim: {
      type: Array,
      default() {
        return []
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitted: false,
      saldo: 0,
      simpananService: null,
    }
  },
  created() {
    this.simpananService = new SimpananService()
  },
  async mounted() {
    this.simpananService = new SimpananService()
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    onChangeSimpanan() {
      this.simpananService
        .saldo(`?anggota_id=${this.anggotaId}&jsim_id=${this.form.jsim_id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.old_status = this.form.status
            this.form.si_at = this.form.si_at ? new Date(this.form.si_at) : null
          }
        })
        .catch((err) => {
          errorHandler(err, 'Simpanan', this)
        })
        .finally(() => (this.isLoading = false))
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
  },
  validations() {
    return {
      form: {
        jsim_id: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        jumlah: {
          required: helpers.withMessage('Min harus diisi.', required),
        },
      },
    }
  },
}
</script>

<style scoped>
.small-message {
  padding: 0.2rem 0.5rem; /* Reduce padding */
  margin: 0.5rem 0; /* Adjust margin */
}
</style>