<template>
  <div>
    <div class="card bg-white">
      <div class="flex justify-content-end mb-2">
        <Button
          :disabled="old_status === 'done'"
          :loading="isLoadingSave"
          icon="pi pi-save"
          class="mr-2"
          :label="id > 0 ? 'Update' : 'Simpan'"
          @click="onConfirmSave(!v$.$invalid)"
        />
        <Button
          :disabled="old_status === 'done'"
          icon="pi pi-plus"
          class="p-button-outlined mr-2"
          label="Item"
          @click="onAddData"
        />
        <Button
          class="p-button-link"
          label="Kembali"
          @click="$router.push({ name: 'Penarikan' })"
        />
      </div>
      <div class="grid">
        <div class="col-8 md:col-6">
          <div class="formgrid grid">
            <div class="field col-12 md:col-5">
              <label>No Penarikan</label>
              <InputText
                v-model="form.psi_no"
                class="w-full"
                input-class="w-full"
                maxlength="50"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Tgl Penarikan</label>
              <Calendar
                :disabled="old_status === 'done'"
                v-model="form.psi_at"
                dateFormat="dd/mm/yy"
                class="w-full"
                input-class="w-full"
              />
            </div>
            <div class="field col-12 md:col-8">
              <label>Anggota</label>
              <AutoComplete
                ref="item"
                v-model="form.anggota"
                class="w-full"
                input-class="w-full"
                :min-length="2"
                :suggestions="acAnggota"
                :field="anggotaLabel"
                autofocus
                :class="{
                  'p-invalid': v$.form.anggota.$invalid && submitted,
                }"
                :disabled="old_status === 'done'"
                placeholder="Search Anggota"
                @complete="searchAnggota($event)"
                @item-select="selectAnggota"
                @focus="selectAllText('item')"
              >
                <template #item="slotProps">
                  <div v-if="slotProps.item">
                    <div>
                      <strong>
                        {{ slotProps.item.kode }} -
                        {{ slotProps.item.nama }}
                      </strong>
                    </div>
                    <div>Alamat: {{ slotProps.item.alamat }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </AutoComplete>
              <small
                v-if="
                  (v$.form.anggota.$invalid && submitted) ||
                  v$.form.anggota.$pending.$response
                "
                class="p-error"
                >{{ v$.form.anggota.required.$message }}</small
              >
            </div>
            <div class="field col-12 md:col-4">
              <label>Status</label>
              <Dropdown
                v-model="form.status"
                :options="list_status"
                optionValue="value"
                optionLabel="label"
                optionDisabled="disabled"
                class="w-full"
                input-class="w-full"
                :disabled="old_status === 'done'"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <fx-table
          :items="form.details"
          :total="form.details.length"
          :loading="isLoading"
          :paginator="false"
          hasFooter
          showGridlines
        >
          <template #columns>
            <Column
              field="nama_simpanan"
              header="nama simpanan"
              style="min-width: 8rem; background-color: #f8f9fa"
            />
            <Column
              field="jumlah"
              header="jumlah"
              style="min-width: 10rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.jumlah) }}
                </span>
              </template>
            </Column>
            <Column style="flex-grow: 1; justify-content: flex-end">
              <template #body="{ data }">
                <Button
                  :disabled="old_status === 'done'"
                  type="button"
                  icon="pi pi-pencil"
                  class="p-button-outlined p-button-secondary p-button-text mr-2"
                  @click="onEditData(data)"
                />
                <Button
                  :disabled="old_status === 'done'"
                  type="button"
                  icon="pi pi-trash"
                  class="p-button-outlined p-button-danger p-button-text"
                  @click="onConfirmDeletion(data)"
                />
              </template>
            </Column>
          </template>
          <template #footer>
            <div class="p-d-flex p-jc-end p-ai-center" style="width: 100%">
              <table style="width: 100%">
                <tr>
                  <td
                    colspan="2"
                    style="text-align: right; padding-right: 1rem"
                  >
                    {{ formatCurrency(totalPenarikan.total || 0) }}
                  </td>
                  <td style="text-align: right"></td>
                </tr>
              </table>
            </div>
          </template>
        </fx-table>
      </div>
    </div>
    <!-- </form> -->
    <Dialog
      :header="editMode ? 'Edit Item Penarikan' : 'Tambah Item Penarikan'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <form-penarikan-item
        :item="formDetail"
        :anggotaId="this.form.anggota_id"
        :loading="isLoadingSave"
        :listJsim="listJsim"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Hapus Penarikan"
      v-model:visible="dialogHapus"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '50vw' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <span
          >Item <strong>{{ formDetail.nama_simpanan }}</strong> akan dihapus.
          Proses ?</span
        >
      </div>
      <template #footer>
        <Button
          label="Hapus"
          icon="pi pi-trash"
          @click="onDeleteItemPenarikan"
          class="p-button-text p-button-danger"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import FxTable from '@/components/FxTable'
import { helpers, required } from '@vuelidate/validators'
import { formatCurrency } from '@/helpers/index'
import AnggotaService from '@/services/AnggotaService'
import PenarikanService from '@/services/PenarikanService'
import JsimService from '@/services/JsimService'
import FormPenarikanItem from '@/components/simpanan/FormPenarikanItem'
import Hotkey from '@/components/Hotkey'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormPenarikanItem,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      submitted: false,
      anggotaService: null,
      penarikanService: null,
      jsimService: null,
      acAnggota: null,
      dialog: false,
      dialogHapus: false,
      isLoading: false,
      isLoadingSave: false,
      items: [],
      listJsim: [],
      editMode: false,
      form: {
        psi_no: '',
        psi_at: new Date(),
        anggota_id: 0,
        status: 'draft',
        details: [],
      },
      formDetail: {
        jsim_id: '',
        jumlah: 1,
      },
      list_status: [
        { value: 'draft', label: 'draft' },
        { value: 'done', label: 'done' },
      ],
      old_status: 'draft',
      listDeleted: [],
    }
  },
  created() {
    this.anggotaService = new AnggotaService()
    this.penarikanService = new PenarikanService()
    this.jsimService = new JsimService()
  },
  computed: {
    totalPenarikan() {
      // jumlah
      let jumlah = this.form.details.reduce((total, detail) => {
        total += detail.jumlah
        return total
      }, 0)

      const total = {
        total: jumlah,
      }
      return total
    },
  },
  mounted() {
    if (this.id > 0) {
      this.isLoading = true
      this.penarikanService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.old_status = this.form.status
            this.form.psi_at = this.form.psi_at
              ? new Date(this.form.psi_at)
              : null
          }
        })
        .catch((err) => {
          errorHandler(err, 'Penarikan', this)
        })
        .finally(() => (this.isLoading = false))
    }

    this.jsimService
      .get()
      .then((res) => {
        this.listJsim = res.data.data.filter(
          (item) => item.id !== 1 && item.id !== 2
        )
      })
      .catch((err) => {
        errorHandler(err, 'Data Items', this)
      })
  },
  methods: {
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    searchAnggota(e) {
      this.anggotaService
        .get(`?filter[nama]=${e.query}&filter[status]=active`)
        .then((res) => {
          this.acAnggota = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Anggota', this)
        })
    },
    selectAnggota(e) {
      this.form.anggota_id = e.value.id
      this.anggotaLabel(e)
    },
    anggotaLabel(e) {
      return e.kode + ' - ' + e.nama
    },
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    onAddData() {
      if (!this.form.anggota_id) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Penarikan',
          detail: 'Anggota harus diisi terlebih dahulu.',
          life: 3000,
        })
        return
      }

      this.editMode = false
      this.formDetail = {
        jsim_id: '',
        jumlah: 0,
      }
      this.dialog = true
    },
    async onEditData(data) {
      this.editMode = true
      this.formDetail = Object.assign({}, data)
      this.dialog = true
    },
    totalDetail(detail) {
      const total = {
        total: detail.jumlah,
      }
      return total
    },
    async onSaveData(data) {
      const detailItem = Object.assign({}, data)

      const idx = this.form.details.findIndex(
        (item) => item.jsim_id === detailItem.jsim_id
      )

      if (this.editMode) {
        Object.assign(this.form.details[idx], { ...detailItem })
        this.dialog = this.editMode ? false : this.dialog
      } else {
        const getJsim =
          this.listJsim.filter((item) => item.id === detailItem.jsim_id)
            .length > 0
            ? this.listJsim.filter((item) => item.id === detailItem.jsim_id)[0]
            : {}
        const payload = {
          nama_simpanan: getJsim.nama,
          ...detailItem,
        }
        if (idx === -1) {
          this.form.details.push(payload)
          this.$toast.add({
            severity: 'success',
            summary: 'Penarikan',
            detail: 'Item ' + payload.nama_simpanan + ' berhasil ditambahkan.',
            life: 3000,
          })
          this.dialog = false
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Penarikan',
            detail: 'Item ' + payload.nama_simpanan + ' sudah ada',
            life: 3000,
          })
        }
      }
    },
    onConfirmDeletion(data) {
      this.formDetail = data
      this.dialogHapus = true
    },
    onDeleteItemPenarikan() {
      const index = this.form.details.findIndex(
        (el) => el.jsim_id === this.formDetail.jsim_id
      )
      if (index !== -1) {
        if (this.id && this.form.details[index].id) {
          this.listDeleted.push(this.form.details[index].id)
        }
        this.form.details.splice(index, 1)
        this.$toast.add({
          severity: 'success',
          summary: 'Data Item Penarikan',
          detail: 'Data berhasil dihapus.',
          life: 3000,
        })
      }
      this.dialogHapus = false
    },
    onConfirmSave(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      if (this.form.details.length === 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Penarikan',
          detail: 'Item simpanan minimal 1 data.',
          life: 3000,
        })
        return
      }

      const deleted = this.id ? { deleted: this.listDeleted } : {}

      const psi_at = this.form.psi_at
        ? { psi_at: dayjs(this.form.psi_at).format('YYYY-MM-DD') }
        : null
      const form = {
        ...psi_at,
        status: this.form.status,
        psi_no: this.form.psi_no,
        anggota_id: this.form.anggota_id,
        details: this.form.details.map((item) => {
          const id = item.id ? { id: item.id } : this.id ? { id: 0 } : {}
          return {
            ...id,
            jsim_id: item.jsim_id,
            jumlah: item.jumlah,
          }
        }),
        ...deleted,
      }

      if (!this.id) {
        this.isLoadingSave = true
        this.penarikanService
          .add(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Penarikan',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.$router.push({ name: 'Penarikan' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Penarikan', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSave = false
          })
      } else {
        this.isLoadingSave = true
        this.penarikanService
          .update(this.id, form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Penarikan',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
              this.$router.push({ name: 'Penarikan' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Penarikan', this)
          })
          .finally(() => {
            this.isLoadingSave = false
          })
      }
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
  },
  validations() {
    return {
      form: {
        psi_at: {
          required: helpers.withMessage('Tgl Penarikan harus diisi.', required),
        },
        anggota: {
          required: helpers.withMessage('Anggota harus diisi.', required),
        },
      },
    }
  },
}
</script>
