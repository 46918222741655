<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="mb-4 flex justify-content-between align-items-center">
      <span v-if="id > 0" class="pr-2 mr-2 font-bold text-2xl">
        Penarikan - Edit</span
      >
      <span v-else class="pr-2 mr-2 font-bold text-2xl">
        Penarikan - Tambah</span
      >
      <div>
        <i class="pi pi-home mr-2"></i>
        <span v-if="id > 0" class="font-small">/ Penarikan - Edit</span>
        <span v-else class="font-small">/ Penarikan - Tambah</span>
      </div>
    </div>
    <form-penarikan :id="id" />
  </div>
</template>

<script>
import FormPenarikan from '@/components/simpanan/FormPenarikan'

export default {
  name: 'PenarikanAdd',
  components: {
    FormPenarikan,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
    }
  },
}
</script>
